import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/Seo'

import Cover from '../components/Cover'
import VideoBox from '../components/VideoBox'
import CTA from '../components/Cta'

import coverImage from '../images/trading-online.jpg'

import posterTradingOnline from '../images/poster-trading-online.png'

const Impulsivita = () => (
  <Layout>
    <SEO title="Trading online" />
    <Cover
      title="Trading online"
      color="white"
      bg={coverImage}
      icon=""
      link="#video"
    >
      <p>
        Prima di farsi abbagliare dalla promessa di facili guadagni, è
        necessario ricordare che i mercati finanziari sono imprevedibili e molto
        variabili per natura: l’espressione “rischi zero” in finanza è una
        bugia. Avventurarsi nel trading online senza una guida esperta,
        competente e affidabile, espone a risultati molto pericolosi.
      </p>
    </Cover>
    <VideoBox ytID="uiYtKx1_wW8" poster={posterTradingOnline} />
    <CTA />
  </Layout>
)

export default Impulsivita
